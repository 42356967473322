
import Vue from 'vue';
import {Component, Watch} from "vue-property-decorator";
import ContainerType from "@/models/ContainerType.model";
import ContainerTypeRepository from "@/api/repositories/ContainerTypeRepository";
import {ContainerTypeStatus} from "@/enum/ContainerTypeStatus";
import {
  ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS,
  ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS_WITH_ID,
  ROUTE_CONTAINER_TYPE_REQUESTS,
  ROUTE_CONTAINER_TYPE_REQUESTS_WITH_ID
} from "@/router/routes";

@Component({
  components: {
    ContainerTypeRequestsList: () => import(
      /* webpackChunkName: "ContainerTypeRequestsList" */
      '@/components/ContainerType/ContainerTypeRequestList.component.vue'
    )
  }
})
export default class CompanyRequestedContainersView extends Vue {
  /**
   * search field text
   * @private
   */
  private search: string = '';

  /**
   * flag that indicates which selection of items is shown
   * @private
   */
  private showProvedItems = false;

  /**
   * flag that indicates if the table is loading
   * @private
   */
  private isLoading = false;

  /**
   * list of open and unhandled container type requests
   * @private
   */
  private pendingContainerTypes: ContainerType[] = [];

  /**
   * list of handled container type requests
   * @private
   */
  private handledContainerTypes: ContainerType[] = [];

  /**
   * changes the selection to the one that is passed
   * @param value
   * @private
   */
  private onSelectionChange(value: boolean): void {
    this.showProvedItems = value;
  }

  /**
   * fetches the containerTypes that are either already edited or still pending in request
   * @private
   */
  private async created(): Promise<void> {
    this.isLoading = true;
    await this.loadTables();
    this.$nextTick(() => this.isLoading = false);
  }

  /**
   * is called when an container type is accepted, changes status of the container and reloads the table
   * @param item
   * @private
   */
  private async onItemAccepted(item: ContainerType): Promise<void> {
    this.isLoading = true;
    try {
      // changes the status of the container type to accepted
      await ContainerTypeRepository.changeContainerTypeStatus(item.id!, true);
      await this.loadTables();
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when an container type is denied, changes status of the container and reloads the table
   * @param item
   * @private
   */
  private async onItemDeclined(item: ContainerType): Promise<void> {
    this.isLoading = true;
    try {
      // changes the status of the container type to declined
      await ContainerTypeRepository.changeContainerTypeStatus(item.id!, false);
      await this.loadTables();
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * reloads the container type arrays
   * @private
   */
  private async loadTables(): Promise<void> {
    try {
      // gets the id of the company based on the route
      const id = this.$route.params.id;

      // gets the requested container types of the selected company
      const response = await ContainerTypeRepository.getRequestedCompanyContainerTypes(id);
      this.handledContainerTypes = response.data.handledContainerTypes;
      this.pendingContainerTypes = response.data.requestedContainerTypes;
    } catch(_) {
      this.handledContainerTypes = [];
      this.pendingContainerTypes = [];
    }
  }

  /**
   * is called when the type list requests a route change, checks if an id was passed and pushes to correct page
   * @param id
   * @private
   */
  private onRouteChangeRequested(id?: string): void {
    if(!id) {
      this.$router.push({name: ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS});
      return;
    }

    this.$router.push({name: ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS_WITH_ID, params: {containerTypeId: id!}});
  }
}
